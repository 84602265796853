import React from "react";
import { Link } from "gatsby";
import useGetImage from "./useGetImage";
import s from "./HeroLogo.module.scss";
const HeroLogo = () => {
    const logo = useGetImage();
    return (<div className={s.logoBox}>
      <img className={s.logo} src={logo.publicURL} alt="Virgil Gamache Farms"/>
      <h1 className={s.title}>GREAT HOPS, FOR GREAT BREWERS</h1>
      <Link className={s.link} to="/hop-varieties">
        Hop Varieties
      </Link>
    </div>);
};
export default HeroLogo;
