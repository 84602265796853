import React from "react";
import Hero from "./components/hero";
import s from "./Home.module.scss";
import loadable from "@loadable/component";
const PreviewContent = loadable(() => import("./components/previewContent"));
const SectionSeparator = loadable(() => import("../../components/sectionSeparator"));
const ContentBox = loadable(() => import("./components/contentBox"));
const Home = () => {
    return (<>
      <div className={s.box}>
        <Hero />
      </div>
      <SectionSeparator />
      <ContentBox>
        <PreviewContent />
      </ContentBox>
    </>);
};
export default Home;
