import { useStaticQuery, graphql } from "gatsby";
export const HeroImage = graphql `
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1920, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
const useGetImages = () => {
    const { allFile: { nodes }, } = useStaticQuery(graphql `
    query {
      allFile(filter: { relativeDirectory: { eq: "images/home_hero" } }) {
        nodes {
          relativePath
          ...HeroImage
        }
      }
    }
  `);
    return nodes;
};
export default useGetImages;
