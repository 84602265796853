import React from "react";
import Home from "../scenes/home/Home";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Home"} keywords={keywords}/>
      <Home />
    </>);
};
