import React from "react";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ISliderSettings from "./sliderSettingsInterface";
export { ISliderSettings };
const Slider = ({ children, ...settings }) => {
    return <Slick {...settings}>{children}</Slick>;
};
export default Slider;
