import React from "react";
import Img from "gatsby-image";
import useGetImages from "./getImagesHook";
import Slider from "../../../../components/slider";
import s from "./Hero.module.scss";
import HeroLogo from "../heroLogo";
const Hero = () => {
    const nodes = useGetImages();
    const settings = {
        dots: true,
        arrow: true,
        autoplay: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        infinite: true,
        className: s.slider,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    dots: false,
                },
            },
        ],
    };
    return (<div className={s.hero}>
      <Slider {...settings}>
        {nodes.map(({ childImageSharp }) => (<div style={{ position: "relative" }}>
            {childImageSharp.fluid.src.split("/")[childImageSharp.fluid.src.split("/").length - 1] !== "Owners.jpg" && (<div style={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
    }}>
                <HeroLogo />
              </div>)}
            <Img className={s.sliderItem} key={childImageSharp.fluid.src} fluid={childImageSharp.fluid}/>
          </div>))}
      </Slider>
    </div>);
};
export default Hero;
