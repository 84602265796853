import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { logo } = useStaticQuery(graphql `
    query {
      logo: file(relativePath: { eq: "images/VGFLogo_White_Shadow.png" }) {
        publicURL
      }
    }
  `);
    return logo;
};
